.navContainer {
	position: fixed;
	height: 75px;
	width: 100%;
	top: 0;
	right: 0;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	transition: all 0.5s ease-out;
}

.navBg {
	background-color: rgb(128, 128, 128);
	transition: all 0.5s ease-out;
}

.navActive {
	color: #39ff14;
}

.navChild {
	display: flex;
	color: rgb(255 255 255);
	margin-top: 5px;
}

.Link {
	text-decoration: none;
	padding: 0 2em 0 0.5em;
}

.linkChild {
	color: rgb(255 255 255);
	position: relative;
}

.linkChild::after {
	content: '';
	position: absolute;
	border-radius: 10px;
	right: 105%;
	bottom: 0;
	width: 3px;
	height: 0;

	transform: scaleY(0);
	transform-origin: bottom;
	transition: transform 150ms ease-in;
}

.linkChild:hover::after {
	background: #39ff14;
	height: 100%;
	transform: scaleY(1.1);
}

.linkChild:hover {
	transition: color 500ms ease 50ms;
	color: #39ff14;
}

.iconWrap {
	margin-top: 20px;
}

.myIcon {
	color: white;
	font-size: 2em;
	padding-left: 5px;
}

.myIcon:hover {
	color: #f7f636;
	cursor: pointer;
	transition: color 0.75s ease;
}

.hide {
	visibility: hidden;
}

.email {
	color: #f7f636;
	position: absolute;
	margin: 0 0 0 10px;
	top: 0;
}
