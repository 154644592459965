.projectsContainer {
	margin: 10%;
	overflow-x: hidden;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	box-shadow: -2px 0 8px 0 rgb(57, 255, 20);
	transition: all 0.5s ease-out;
}

.projectsContainer:hover {
	transition: all 0.5s ease;
	box-shadow: none;
}

.card {
	background-color: black;
	width: 30em;
	margin: 10px;
	position: relative;
	border-radius: 15px;
	overflow: hidden;
	transition: box-shadow 0.5s ease-out;
}

.card:hover {
	box-shadow: 0 0 8px 0 rgba(57, 255, 20, 0.9);
	transition: box-shadow 0.5s ease-in;
}

.cardContent {
	color: white;
	text-align: center;
}

.pics {
	width: 100%;
	height: 100%;
}

.showLinks {
	width: 100%;
	height: 100%;
	background-color: blue;
}

.description {
	margin: 0 5px 0 5px;
	text-align: center;
}

.myIcon {
	color: white;
	font-size: 2em;
	padding-left: 5px;
}

.myIcon:hover {
	color: #f7f636;
	cursor: pointer;
	transition: color 0.75s ease;
}
